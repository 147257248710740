<template>
    <div id="app" class="goals-content goals-bg">
        <header class="goals-header">
            <div class="header-logo"></div>
            <ul>
                <li>
                    <a href="https://cms.coursemo.com">CMS</a>
                </li>
                <li>
                    <a href="">章节</a>
                </li>
                <li class="liActive">
                    <a href="javascript:">学业规划</a>
                </li>
                <li>
                    <a href="/guide/papers/alevel/">Papers</a>
                </li>
            </ul>
        </header>
        <div class="menu">
            <div class="title">G5指数</div>
            <div class="school" :class="{'cur':school=='Oxford'}" @click="changeSchool('Oxford')">Oxford</div>
            <div class="school" :class="{'cur':school=='Cambridge'}" @click="changeSchool('Cambridge')">Cambridge</div>
            <div class="school" :class="{'cur':school=='Imperial'}" @click="changeSchool('Imperial')">Imperial</div>
            <div class="school" :class="{'cur':school=='UCL'}" @click="changeSchool('UCL')">UCL</div>
            <div class="school" :class="{'cur':school=='LSE'}" @click="changeSchool('LSE')">LSE</div>
        </div>
        <van-loading v-if="isLoading" style="position:fixed;left: 50%;top:50%;" type="spinner" color="#1989fa"/>
        <div class="content" v-if="!isLoading">
            <div class="goals-box">
                <div class="goals-title" v-cloak>{{school}} <span> {{year}}</span></div>
            </div>
            <div class="goals-box">
                <div class="igcse-bg" @click="clearYear">年份</div>
                <div class="goals-right" v-cloak>
                    <div class="year-item" :class="{'year-active':item == year}" v-for="(item,index) in years"
                         :key='index' @click="changeYear(item)">
                        {{item}}
                    </div>
                </div>
            </div>
            <div class="goals-box">
                <div class="igcse-bg alevel-bg" @click="clearCourse">专业</div>
                <div class="goals-right" v-cloak style="padding-bottom: 10px;">
                    <div class="course-item" :class="{'year-active':item.course_en == course}"
                         v-for="(item,index) in courses" :key='index' @click="changeCourse(item.course_en)">{{item.course}}
                    </div>
                </div>
            </div>
            <div class="goals-view">

                <div class="filtr-item" v-for="(item,index) in list" :key="index">
                    <div class="data">
                        <div class="item-year">{{item.year}}</div>
                        <div class="item-name">{{item.name}}</div>
                        <div class="item-course">{{item.course}}</div>
                        <div class="item-score" v-if="item.grade_scores.length>0">Scores：


                            <van-tabs>
                                <van-tab v-for="(item2, index2) in item.grade_scores" :key="index2" :title="item2.type">
                                    <van-row justify="center">
                                        <van-col span="12">
                                            <div class="c-title">Subject</div>
                                        </van-col>
                                        <van-col span="6">
                                            <div class="c-title c-item-center">Mark</div>
                                        </van-col>
                                        <van-col span="6">
                                            <div class="c-title c-item-center">Grade</div>
                                        </van-col>
                                    </van-row>
                                    <van-row justify="center" type="flex" v-for="(item3, index3) in item2.scores"
                                             :key="index3">
                                        <van-col span="12">
                                            <div class="c-item">{{item3.subject}}</div>
                                        </van-col>
                                        <van-col span="6">
                                            <div class="c-item c-item-center">{{item3.mark}}</div>
                                        </van-col>
                                        <van-col span="6">
                                            <div class="c-item c-item-center">{{item3.grade}}</div>
                                        </van-col>
                                    </van-row>

                                </van-tab>
                            </van-tabs>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import axios from 'axios';

    export default {
        name: 'app',
        // el: "#app",
        data: function () {
            return {
                isLoading: false,
                years: [],
                courses: [],
                year: null,
                course: null,
                list: [],
                school: "Oxford",
                activeData: 'igcse_mathmetics',
                defaultProps: {
                    children: "children",
                    label: "label"
                },
            };
        },
        created() {
            this.isLoading = true;
            this.getFilters();
            this.getAllData();
        },
        methods: {
            changeSchool(val) {
                if (this.school != val) {
                    this.isLoading = true;
                    this.school = val;
                    this.getFilters();
                    this.getAllData();
                }
            },
            getFilters() {
                var _this = this;
                var xhr = new XMLHttpRequest();
                //监听响应
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
                        var datas = JSON.parse(xhr.responseText);
                        console.log(datas);
                        if (datas.code == 0) {
                            _this.years = datas.data.years;
                            _this.courses = datas.data.courses;
                        } else {
                            _this.$message({
                                showClose: true,
                                message: datas.msg,
                                type: "error"
                            });
                        }
                    }
                };
                xhr.open("GET", 'https://guide.coursemo.com/api/g5/filter.php?school=' + _this.school, true);
                xhr.send();
            },
            getAllData() {
                var _this = this;
                console.log("getAllData  time1===", Date.parse(new Date()));
                axios.get('https://guide.coursemo.com/api/g5/student.php', {
                    params: {
                        school: _this.school
                    }
                }).then(function (response) {
                    //console.log(response);
                    _this.isLoading = false;
                    console.log("getAllData  time2===", Date.parse(new Date()));
                    if (response.data.code == 0) {
                        _this.list = response.data.data;

                        console.log("getAllData  time3===", Date.parse(new Date()))
                    } else {
                        _this.$message({
                            showClose: true,
                            message: response.msg,
                            type: "error"
                        });
                    }
                }).catch(function (error) {
                    console.log(error);
                });
                /*    var xhr = new XMLHttpRequest();
                    console.log("getAllData  time1===", Date.parse(new Date()));

                    xhr.onreadystatechange = function () {
                        setTimeout(() => {
                            _this.isLoading = false;
                        }, 50);
                        if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
                            //console.log("getAllData  time2===", Date.parse(new Date()));
                           // console.log("getAllData  responseText===", xhr.responseText);

                            var datas = JSON.parse(xhr.responseText);
                           // console.log(datas);
                            if (datas.code == 0) {
                                _this.list = datas.data;
                              /!* *!/
                                console.log("getAllData  time3===", Date.parse(new Date()))
                            } else {
                                _this.$message({
                                    showClose: true,
                                    message: datas.msg,
                                    type: "error"
                                });
                            }
                        }
                    };
                    xhr.open("GET", 'https://guide.coursemo.com/api/g5/student.php?school=' + _this.school, true);
                    xhr.send();*/
            },
            clearYear() {
                this.year = null;
                this.getAllData();
            },
            changeYear(year_) {
                var _this = this;
                _this.year = year_;
                var xhr = new XMLHttpRequest();
                //监听响应
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
                        var datas = JSON.parse(xhr.responseText);
                        console.log(datas);
                        if (datas.code == 0) {
                            _this.list = datas.data;
                        } else {
                            _this.$message({
                                showClose: true,
                                message: datas.msg,
                                type: "error"
                            });
                        }
                    }
                };
                xhr.open("GET", 'https://guide.coursemo.com/api/g5/student_year.php?school=' + _this.school + "&year=" + _this.year, true);
                xhr.send();
            },
            clearCourse() {
                this.course = null;
                this.getAllData();
            },
            changeCourse(course_) {
                var _this = this;
                _this.course = course_;
                var xhr = new XMLHttpRequest();
                //监听响应
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4 && (xhr.status === 200 || xhr.status === 304)) {
                        var datas = JSON.parse(xhr.responseText);
                        console.log(datas);
                        if (datas.code == 0) {
                            _this.list = datas.data;
                        } else {
                            _this.$message({
                                showClose: true,
                                message: datas.msg,
                                type: "error"
                            });
                        }
                    }
                };
                xhr.open("GET", 'https://guide.coursemo.com/api/g5/student_course.php?school=' + _this.school + "&course=" + _this.course, true);
                xhr.send();
            }

        }


    }
</script>

<style>
    body {
        background: #f7f7f7;
        overflow-y: scroll;
    }

    html,
    body,
    h2,
    dl,
    dt,
    dd,
    ul,
    li {
        padding: 0px;
        margin: 0px;
    }

    .goals-bg {
        width: 100%;
        margin: 0 auto;
        margin-top: 65px;
    }

    .content {
        width: calc(100% - 320px);
        margin: 0 auto;
    }

    .c-title {
        font-weight: bold;
        font-size: 16px;
        color: #333333;
        line-height: 30px;
    }

    .c-item {
        font-size: 14px;
        color: #333333;
        line-height: 26px;
    }

    .c-item-center {
        text-align: center;
    }

    .item-score >>> .el-tabs__header {
        margin-bottom: 0px;
    }

    .item-score >>> .el-table td, .item-score >>> .el-table th {
        padding: 8px 0px;
    }

    .goals-box {
        background: #fff;
        margin: 15px auto;
        width: 1200px;
        min-height: 60px;
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        flex-direction: row;
    }

    .year-item {
        width: 80px;
        height: 40px;
        margin: 10px;
        line-height: 40px;
        border: 1px solid #dedede;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
    }

    .course-item {
        margin: 10px 0px 0px 10px;
        padding: 4px 6px;
        line-height: 20px;
        border: 1px solid #dedede;
        text-align: center;
        cursor: pointer;
        box-sizing: border-box;
        font-size: 14px;
        white-space: pre;
    }

    .year-active {
        border: 1px solid #007AFF;
        background-color: #007AFF;
        color: white;
    }

    .igcse-bg {
        width: 40px;
        min-height: 40px;
        line-height: 40px;
        border-radius: 8px 0px 0px 8px;
        font-family: PingFangSC-Semibold;
        font-size: 14px;
        color: #FFFFFF;
        text-align: center;
        background-color: #007AFF;
        /* background: url(images/igcse-bg.png) no-repeat center center; */
        background-size: 100%;
        writing-mode: tb-rl;
        cursor: pointer;
    }

    .alevel-bg {
        background-color: #007AFF;
        /* background: url(images/alevel-bg.png) no-repeat center center; */
        background-size: 100%;
    }

    .goals-right {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .goals-right dl {
        float: left;
        padding: 9px 20px;
        margin: 0px;
        cursor: pointer;
    }

    .goals-right dt {
        text-align: center;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #666666;
        line-height: 20px;
    }

    .goals-right dd {
        text-align: center;
        margin: 0px;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #999999;
        line-height: 17px;
    }

    .goals-title {
        font-family: PingFangSC-Semibold;
        font-size: 26px;
        color: #4F544D;
        padding: 12px;
        height: 37px;
        line-height: 37px;
    }

    .goals-right .goals-active {
        color: #1890FF;
    }

    .goals-view {
        width: 1200px;
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .goals-view .el-tree-node__expand-icon {
        -webkit-transform: rotate(0);
        transform: rotate(0);
    }


    .menu {
        position: fixed;
        left: 0;
        top: 50px;
        bottom: 0;
        width: 160px;
        padding-top: 100px;
        font-family: Consolas, arial, "宋体";
        background-color: #fff;
        overflow-y: auto;
        z-index: 99999;
    }

    .title {
        width: 150px;
        height: 200px;
        font-family: Consolas, arial, "宋体";
        background-color: #fff;
        overflow-y: auto;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        -webkit-background-clip: text;
        color: transparent;
        color: #222222;
    }


    .menu .school {
        display: block;
        height: 50px;
        padding-left: 40px;
        line-height: 50px;
        font-size: 14px;
        color: #333;
        text-decoration: none;
        font-weight: bold;
        cursor: pointer;
    }

    .menu .cur {
        background-color: #6532DF !important;
        color: white;
    }

    .goals-icon {
        width: 14px;
        height: 16px;
        padding: 0px;
        background: url(assets/topic-bg.png) no-repeat center center;
        background-size: 100%;
        margin-right: 10px;
    }

    .goals-view .el-tree-node__content > .el-tree-node__expand-icon {
        padding: 0px;
    }

    .goals-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: #3C3E41;
        overflow: hidden;
        z-index: 99;
    }

    .goals-view .el-tree-node {
        margin-left: 20px;
    }

    .goals-view .el-tree-node:focus > .el-tree-node__content,
    .goals-view .el-tree-node__content:hover {
        background: #E1F3FF;
    }

    .header-logo {
        width: 128px;
        height: 18px;
        margin: 16px 112px 16px 30px;
        float: left;
        background: url(assets/logo.png) no-repeat center center;
        background-size: 100%;
    }

    .goals-header ul,
    .goals-header li {
        list-style: none;
        float: left;
    }

    .goals-header li,
    .goals-header a {
        float: left;
        text-decoration: none;
        width: 107px;
        height: 50px;
        font-family: PingFangSC-Medium;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 50px;
        text-align: center;
    }

    .goals-header .liActive {
        background: #1890FF;
    }

    [v-cloak] {
        display: none;
    }


    .filtr-item {
        display: inline-block;
        width: 380px;
        /*margin: 15px;*/

    }

    .filtr-item .data {
        background: #FFFFFF;
        box-shadow: 0 4px 12px 0 rgba(161, 161, 161, 0.50);
        margin-bottom: 20px;
        min-height: 420px;
        padding-top: 10px;
        border-radius: 8px;
        position: relative;
    }

    .item-year {
        font-size: 16px;
        color: #333333;
        margin-top: 10px;
        margin-left: 100px;
    }

    .item-name {
        font-size: 18px;
        color: #333333;
        margin-left: 20px;
        margin-top: -25px;
        font-weight: bold;
    }

    .item-course {
        margin-left: 20px;
        font-size: 16px;
        color: #999999;
        margin-top: 5px;
    }

    .item-score {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 5px;
        font-size: 14px;
        color: #333333;
    }

    .item-score .nav-tabs {
        margin-top: 6px;
    }

    .school-title {
        display: flex;
        flex-direction: row;
        width: 1200px;
        margin: 35px auto;
        margin-top: 85px;
    }

    .school-title img {
        width: 75px;
        height: 75px;
        display: inline-block;
    }

    .school-title .school-name {
        line-height: 74px;
        font-size: 34px;
        color: rgba(0, 0, 0, 0.85);
        font-weight: bolder;
        margin-left: 20px;
    }

    .hr-line {
        width: 1200px;
        margin: 20px auto;
        border: 1px solid #D2D2D2;
        margin-top: -15px;
    }


</style>
