import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
Vue.use(ElementUI)
import { Tab, Tabs, Col, Row,Loading, List  } from 'vant';
import 'vant/lib/index.css'
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Col);
Vue.use(Row);
Vue.use(Loading);
Vue.use(List);

new Vue({
  render: h => h(App),
}).$mount('#app')
